import styled from 'react-emotion';
import { Axis, Transition } from '../../../typing/enums';

export const CarouselItemWrapper = styled('div')`
  user-select: none;
`;

export const SlideItemWrapper = styled('div')`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.itemWidth}px;
  height: ${props => props.itemHeight}px;
  overflow: hidden;
  margin-${props => (props.axis === Axis.VERTICAL ? 'bottom' : 'right')}: ${props =>
  props.spacing}px;
  cursor: pointer;
`;

export const ItemWrapper = styled('div')`
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  opacity: ${props => (props.show || (props.show && props.transition === Transition.NONE) ? 1 : 0)};
  z-index: ${props => (props.show || (props.show && props.transition === Transition.NONE) ? 1 : 0)};
  ${props => (props.transition === Transition.FADE ? 'transition: opacity 0.25s ease-in;' : '')}
  width: ${props => props.itemWidth}px;
  height: ${props => props.itemHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
